import { ref, computed } from "vue";

export function useBookingRequests(email: string) {
  const bookingRequests = ref([]);
  const isLoading = ref(false);

  const fetchRequests = async () => {
    isLoading.value = true;
    try {
      const BOOKING_REQUESTS_QUERY = `
        query BookingRequests($email: String!) {
        allRentals(filter: {primaryContactEmail: {eq: $email}}) {
          id
          inquiryStatus
          dates {
            slots {
              approvalStatus
            }
          }
        }
      }
      `;
      const { data, error } = await useGraphqlQuery({
        query: BOOKING_REQUESTS_QUERY,
        variables: { email },
        includeDrafts: true,
      });

      bookingRequests.value = data.value?.allRentals || [];
      if (error.value) console.error(error.value);
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
    }
  };

  const hasPendingRequests = computed(() =>
    bookingRequests.value.some(
      (rental) =>
        rental.inquiryStatus === "pending" ||
        rental.dates.some((date) =>
          date.slots.some((slot) => slot.approvalStatus === "pending")
        )
    )
  );

  return { bookingRequests, fetchRequests, isLoading, hasPendingRequests };
}
