<script setup>
const props = defineProps({
  showDescription: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["linkClicked"]);

const QUERY = `
  query {
    allMenuItems(orderBy: position_ASC) {
      title
      position
      path
      menuDescription
    }
  }
`;

// Use the updated composable
const { data, error, pending } = useGraphqlQuery({ query: QUERY });

watchEffect(() => {
  if (data.value) {
    console.log("[DEBUG] Fetched navigation data:", data.value);
  }
  if (error.value) {
    console.error("[DEBUG] Error fetching navigation data:", error.value);
  }
});

const linkClicked = () => {
  emits("link-clicked");
};
</script>

<template>
  <div>
    <ul class="nav">
      <li v-if="!pending" v-for="item in data?.allMenuItems || []" :key="item.title" class="menu-item">
        <div class="description" v-if="showDescription">{{ item.menuDescription }}</div>
        <NuxtLink :to="item.path" @click="linkClicked">
          {{ item.title }}
        </NuxtLink>
      </li>
      <li v-else>
        <span>Loading...</span>
      </li>
    </ul>
    <div v-if="error">{{ error.message }}</div>
  </div>
</template>
