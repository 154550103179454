<template>
  <div class="fixed top-5 right-5 z-50 flex items-center justify-end">
    <div class="flex items-center space-x-4 backdrop-blur-sm rounded-md p-2">
      <template v-if="!userStore.userInfo?.email">
        <a @click.prevent="openLoginModal" href="#" class="text-cream text-sm hover:underline whitespace-nowrap">
          Login
        </a>
      </template>

      <template v-else>
        <div class="flex items-center space-x-4">
          <span v-if="userStore?.userInfo?.firstName" class="text-sm whitespace-nowrap">
            <NuxtLink to="/dashboard" class="underline">
              Hi, {{
                userStore?.userInfo?.firstName }}.
            </NuxtLink>
          </span>
          <!-- <NuxtLink v-if="hasPendingRequests" to="/dashboard/bookings" class="flex items-center space-x-2">
            <span class="underline text-sm cursor-pointer">View Booking Requests</span>
          </NuxtLink> -->
          <div @click="handleLogout" class="underline text-sm cursor-pointer whitespace-nowrap">
            Logout
          </div>
        </div>
      </template>

      <button class="menu-toggle flex items-center cursor-pointer border-none ml-4" @click="toggleMenu"
        :aria-expanded="isMenuOpen" aria-controls="menu">
        <div class="menu-toggle_icon">
          <span :class="{ open: isMenuOpen }"></span>
        </div>
        <div class="menu-toggle_label text-sm whitespace-nowrap">{{ menuLabel }}</div>
      </button>
    </div>
  </div>

  <div id="menu" class="z-10 absolute top-0 flyover-menu h-full" :class="{ show: isMenuOpen }">
    <div class="flex flex-col h-full justify-start">
      <div id="logo" class="logo-alignment max-w-[1200px]">
        <NuxtLink to="/">
          <Logo />
        </NuxtLink>
      </div>
      <div class="z-20  h-full lg:mt-0 -mt-10 md:-mt-20">
        <Navigation :showDescription="true" @link-clicked="toggleMenu" />
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/userStore';
import { useMenuToggle } from '@/composables/useMenuToggle';
import { useBookingRequests } from '@/composables/useBookingRequests';
import { useLoginModalStore } from '@/stores/loginModalStore';

const userStore = useUserStore();
const loginModalStore = useLoginModalStore();
const { isMenuOpen, toggleMenu, menuLabel } = useMenuToggle();
const route = useRoute();

onMounted(async () => {
  try {
    await userStore.fetchUserProfile();
    // Only fetch requests if user is authenticated with email
    if (userStore.userInfo?.authenticated && userStore.userInfo?.email) {
      const { fetchRequests } = useBookingRequests(userStore.userInfo.email);
      await fetchRequests();
    }
  } catch (error) {
    console.error('Error in MenuToggle mount:', error);
  }
});

watch(route, () => {
  isMenuOpen.value = false;
});

function openLoginModal() {
  loginModalStore.open();
}


async function handleLogout() {
  await userStore.logout();
  navigateTo('/');
}
</script>
