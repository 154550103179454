import { ref, computed } from "vue";

export function useMenuToggle() {
  const isMenuOpen = ref(false);

  const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
  };

  const menuLabel = computed(() => (isMenuOpen.value ? "Close" : "Menu"));

  return { isMenuOpen, toggleMenu, menuLabel };
}
